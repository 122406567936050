<template>
  <div class="location-placeholder" @click="locationsStore.toggleLocationSelectionModal">
    <div class="flex justify-content-center align-content-center">
      <IconsLocationButtonIcon />
    </div>
    <p>{{ placeLabelAccordingToSelectedMethod }}</p>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "#i18n";
import { useLocationSlice } from "~/store/locationSlice";
import { OrderMethod, useOrderMethodStore } from "~/store/orderMethodSlice";

const { t } = useI18n();
const orderMethodStore = useOrderMethodStore();
const { selectedMethod, selectedLocation } = storeToRefs(orderMethodStore);
const locationsStore = useLocationSlice();
const { selectedRestaurant } = storeToRefs(locationsStore);
const placeLabelAccordingToSelectedMethod = computed(() => {
  return selectedMethod.value === OrderMethod.DELIVERY
    ? selectedLocation.value
      ? selectedLocation.value.adressetekst
      : t("Selectlocation")
    : selectedRestaurant.value
      ? selectedRestaurant.value.name
      : t("Selectlocation");
});
</script>
